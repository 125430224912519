.footerContainer
  text-align: center
  width: 95%

  @media (min-width: 600px)
    width: 100%

.usingNlp
  font-size: 14px
  font-family: "Noto Sans"
  color: rgb(101, 101, 101)
  line-height: 1.571
  text-align: center

.example
  font-size: 14px
  font-family: "Noto Sans"
  color: rgb(101, 101, 101)
  line-height: 1.571
  text-align: center
  font-weight: bold
  
.whenWill
  font-size: 14px
  font-family: "Noto Sans"
  color: rgb(101, 101, 101)
  line-height: 1.571
  text-align: center
  font-style: italic
  font-weight: normal

.copyright
  font-size: 12px
  font-family: "Noto Sans"
  color: rgb(101, 101, 101)
  line-height: 1.5
  text-align: center
