.logo
  width: 70%
  
  @media (min-width: 600px)
    width: 100%

.headerWrapper
  text-align: center

.findanswers
  font-size: 15px
  font-family: "Noto Sans"
  color: rgb(101, 101, 101)
  font-style: italic
  line-height: 1.5
  text-align: center

  @media (min-width: 600px)
    font-size: 16px
