@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;800&display=swap')

.app
  min-height: 100vh
  background-color: rgb(238, 242, 243)

.container
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  min-height: 100vh
  max-width: 100%
  min-width: 360px
  margin: auto
  padding: 50px 15px 25px

  @media (min-width: 600px)
    max-width: 570px
    justify-content: center
