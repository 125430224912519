.answers_title
    font-size: 15px
    font-family: "Noto Sans"
    color: rgb(207, 41, 50)
    font-weight: bold
    text-transform: uppercase
    line-height: 1.5
    text-align: center

.noresults_title
    text-transform: initial
    margin: 30px 20px 0 20px

.column_header
    font-size: 14px
    font-family: "Noto Sans"
    color: rgb(48, 49, 51)
    font-weight: bold
    line-height: 1.5
    text-align: left
    width: 180px
    border-bottom: rgb(173, 173, 173) 1px solid
    padding-bottom: 5px
    text-transform: capitalize

.standard_column_text
    font-size: 14px
    font-family: "Noto Sans"
    color: rgb(48, 49, 51)
    line-height: 2.143
    text-align: left
    width: 180px

.red_answer_text
    font-size: 14px
    font-family: "Noto Sans"
    color: rgb(207, 41, 50)
    font-weight: bolder
    line-height: 2.143
    text-align: left
    width: 180px

.answer_background_container
    margin-top: 20px
    border-style: solid
    border-width: 1px
    border-color: rgb(207, 41, 50)
    border-radius: 30px
    background-color: rgb(255, 255, 255)
    animation-name: growth
    animation-duration: .5s

.Line_under_column_headers
    background-color: rgb(173, 173, 173)
    height: 1px

.alternating_row_background_color
    background-color: rgb(238, 242, 243)

.table_results
    margin: 15px 15px 10px 15px
    border-spacing: 0px

.answer_headers
    text-align: center
    position: relative
    margin-right: auto
    margin-left: auto

    img
        position: absolute
        top: -13px
        right: calc(50% - 15px)

    span
        position: absolute
        margin-top: 13px
        width: 100%
        text-align: center
        
@keyframes growth
    from
        transform: scale(0)
    to
        transform: scale(1)

.mobile_layout
    margin: 5px 30px
    min-width: 300px

    .divMobileFlex
        display: flex
        flex-direction: row
        align-items: top
        padding: 3px
        margin: 0px 0px 0px 0px
 
        .span_left
            vertical-align: top
            text-align: right
            width: 100%
            font-weight: bolder
            text-transform: capitalize

        .span_right
            text-align: left
            width: 100%
            line-height: normal
            margin-left: 5px

            .red_answer_text
                font-weight: bolder

.span_long_left
    vertical-align: top
    font-weight: bolder
    text-transform: capitalize

.span_long_right
    width: 100%
    line-height: normal
    text-transform: lowercase
    margin-top: 0px

.span_long_title
    width: 100%
    color: rgb(48, 49, 51)
    text-transform: capitalize
    font-weight: bolder
    margin: 0px
    padding: 0px

.long_margin
    margin: 20px